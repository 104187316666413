
import {defineComponent} from 'vue';
import {mapGetters,mapActions} from "vuex";
import AdminPanel from "../components/AdminPanel.vue";
import Table from "@/components/Table.vue";
import Form from "@/components/Form.vue";
import apiService from "@/api/api";
import PRODUCT, {newProduct} from "@/interfaces/product";

export default defineComponent({
  name: 'Console',
  components: {
    AdminPanel, Table, Form
  },
  data() {
    let formData: any = {}
    let tableData: any[] = []
    return {
      showForm: false,
      currentTab: '',
      formAction: '',
      formData,
      tableData
    }
  },
  computed: {
    ...mapGetters([
      'PRODUCTS',
      'CATEGORIES',
      'USERS',
      'USER',
      'LANG'
      // ...
    ]),
    buttonText(): string {
      switch (this.currentTab) {
        default:
         return ''
        case 'categories':
          return 'категорию'
        case 'products':
          return 'товар'
        case 'users':
          return 'користувача'
        case 'posts':
          return 'пост'
        case 'gallery':
          return 'галерею'
        case 'contacts':
          return 'контакти'
        case 'list':
          return 'список речей'
        case 'banks':
          return 'банку'
      }
    },
    buttonsActivated(): boolean {
      return Object.keys(this.formData).length > 0
    }
  },
  watch: {
    currentTab: function () {
      console.log('Current tab watch: ', this.currentTab)
      this.formData = {}
      this.refreshTableData()
    },
  },
  methods: {
    ...mapActions([
      'notify',
      'changeLang'
    ]),
    showDelBtn(): boolean {
      // this.$forceUpdate()
      return (this.currentTab !== 'users' && this.currentTab !== 'gallery' && this.currentTab !== 'contacts' && this.currentTab !== 'list')
          || (this.USER.id !== this.formData.id && this.USER.role > this.formData.role)
    },
    refreshTableData() {
      this.tableData = []
      switch (this.currentTab) {
        default:
          break
        case 'contacts':
          apiService.get('c').then((r: any): any => {
            console.log('c response: ', r)
            if (r.data.error) return
            this.formAction = 'edit'
            if (r.data.contacts) {
              this.tableData = [r.data.contacts]
            }
          })
          break
        case 'products':
          apiService.get('ps').then((r: any): any => {
            console.log('ps response: ', r)
            if (r.data.error) return
            if (r.data.products && r.data.products.length) {
              const array = []
              for (let i = 0; i < r.data.products.length; i++) {
                array.push(newProduct(r.data.products[i]))
              }
              this.tableData = array
              // this.$forceUpdate()
            }
          })
          break
        case 'users':
          apiService.get('a-list').then((r: any): any => {
            console.log('a-list response: ', r)
            if (r.data.error) return
            this.tableData = r.data.users || []
          })
          break
        case 'posts':
          apiService.get('b').then((r: any): any => {
            console.log('posts response: ', r)
            if (r.data.error) return
            this.tableData = r.data.posts || []
          })
          break
        case 'gallery':
          apiService.get('g').then((r: any): any => {
            console.log('gallery response: ', r)
            if (r.data.error) return
            this.formAction = 'edit'
            this.tableData = [r.data.gallery] || []
          })
          break
        case 'list':
        case 'banks':
          if (this.currentTab === 'list') this.formAction = 'edit'
           apiService.get('l').then((r: any): any => {
            console.log(this.currentTab, ' response: ', r)
            if (r.data.error) return
            if (this.getTabKey() === 'l') this.tableData = [r.data.list.needs]
            else this.tableData = r.data.list.banks || []
          })
          break
        case 'visitors':
          apiService.get('visitors').then((r: any): any => {
            this.tableData = r.data || []
          })
      }
    },
    currentTabIs(tab: string): boolean {
      // console.log('curr tab: |', this.currentTab, '| ', tab)
      return this.currentTab === tab
    },
    onMenuClick(event: string) {
      // console.log('on menu click: ', event)
      this.currentTab = event
    },
    onTableRowSelect(event: any) {
      // console.log('row selected: ', JSON.stringify(event))
      if (event != null) this.formData = event
      else this.formData = {}
    },
    onButtonClick(action: string) {
      // console.log('btn-clck', action)
      // if (event.action === 'edit' || event.action === 'del') this.formData = event.data
      this.formAction = action
      this.showForm = true
    },
    getTabKey(): string {
      switch (this.currentTab) {
        case 'contacts':
          return 'c'
        case 'users':
          return 'a'
        case 'products':
          return 'p'
        case 'posts':
          return 'b'
        case 'gallery':
          return 'g'
        case 'list':
          return 'l'
        case 'banks':
          return 'bn'
        default:
            return ''
        }
    },
    async submitForm(event: any) {
      console.log('submit form: ', event)
      if (!event || !event.action || !event.data) return
      const key = this.getTabKey()
      switch (event.action) {
        case 'add':
          apiService.insert(key, event.data, event.images != null ? event.images : event.image, event.video).then((r: any): any => {
            console.log('insert response: ', r)
            if (!r.data.error) {
              this.notify({type: 's', text: 'Успiшно збережено'})
              this.refreshTableData()
              this.closeForm()
            }
          }).catch((e: any) => {
            // console.error(e)
            this.notify({type: 'd', text: e})
          })
          break
        case 'edit':
          apiService.update(key, event.data, event.images != null ? event.images : event.image != null ? event.image : event.avatar, event.video).then((r: any): any => {
            console.log('update response: ', r)
            if (!r.data.error) {
              this.notify({type: 's', text: 'Успiшно вiдредаговано'})
              this.refreshTableData()
              this.closeForm()
            }
          }).catch((e: any) => {
            this.notify({type: 'd', text: e})
          })
          break
        case 'del':
          apiService.remove(key, event.data.id).then((r: any): any => {
            console.log('delete response: ', r)
            if (!r.data.error) {
              this.notify({type: 's', text: 'Успiшно видалено'})
              this.refreshTableData()
              this.closeForm()
            }
          }).catch((e: any) => {
            this.notify({type: 'd', text: e})
          })
          break
        default:
            return
      }
    },
    closeForm() {
      this.showForm = false
      this.formAction = ''
    }
  }
});
